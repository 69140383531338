import { ThemeProvider } from '@material-tailwind/react'
import { ErrorBoundary, LEVEL_WARN, Provider as RollbarProvider } from '@rollbar/react'
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import Rollbar from 'rollbar'

import './App.css'
import { AuthContextProvider } from './context/AuthContext'
import { CmsContextProvider } from './context/CmsContext/CmsContextProvider'
import { ModeContextProvider } from './context/ModeContext'
import { UserContextProvider } from './context/UserContext/UserContextProvider'
import AppRoutes from './routes'

const rollbar = new Rollbar({
	accessToken: import.meta.env.VITE_ELECCA_ROLLBAR_ACCESS_TOKEN as string,
	captureUncaught: true,
	captureUnhandledRejections: true,
	enabled: false,
	environment: 'development',
	payload: {
		client: {
			javascript: {
				code_version: '1.0.0',
				source_map_enabled: true
			}
		}
	}
})

const queryClient = new QueryClient({
	queryCache: new QueryCache({
		onError: (error) => {
			rollbar.error('API call error:', typeof error === 'object' ? JSON.stringify(error) : error)
		}
	})
})

const App = () => {
	return (
		<RollbarProvider instance={rollbar}>
			<ErrorBoundary level={LEVEL_WARN}>
				<QueryClientProvider client={queryClient}>
					<ThemeProvider>
						<AuthContextProvider>
							<UserContextProvider>
								<ModeContextProvider>
									<CmsContextProvider>
										<BrowserRouter>
											<AppRoutes />
										</BrowserRouter>
									</CmsContextProvider>
								</ModeContextProvider>
							</UserContextProvider>
						</AuthContextProvider>
					</ThemeProvider>
				</QueryClientProvider>
			</ErrorBoundary>
		</RollbarProvider>
	)
}

export default App
