import axios, { AxiosError } from 'axios'

import config from '../../config'

const basePath = config.CPO_BACKEND_URL

/* LOGIN */
export const login = async (body: Record<string, unknown>) => {
	try {
		const res = await axios.post<{ token: string; refreshToken: string; message: string }>(
			`${basePath}v2/auth/login`,
			body
		)
		return res
	} catch (error) {
		return error as AxiosError
	}
}

/* REGISTER */
export const register = async (body: Record<string, unknown>) => {
	try {
		const res = await axios.post<{ token: string; refreshToken: string; message: string }>(
			`${basePath}v2/auth/register`,
			body
		)
		return res
	} catch (error) {
		return error as AxiosError<{ message: string }>
	}
}

/* REQUEST OTP */
export const sendOtp = async (body: Record<string, unknown>) => {
	try {
		const res = await axios.post<{ message: string }>(`${basePath}v2/auth/otp`, body)
		return res
	} catch (error) {
		return error as AxiosError
	}
}

/* CHECK OTP */
export const checkOtp = async (body: Record<string, unknown>) => {
	try {
		const res = await axios.post(`${basePath}v2/auth/check-otp`, body)
		return res
	} catch (error) {
		return error as AxiosError
	}
}

/* CHECK PHONE NUMBER */
export const checkPhoneNumber = async (body: Record<string, unknown>) => {
	try {
		const res = await axios.post(`${basePath}v2/auth/check-phone`, body)
		return res
	} catch (error) {
		return error as AxiosError
	}
}

/* REFRESH */
export const refresh = async () => {
	try {
		const res = await axios.post<{ token: string }>(
			`${basePath}v2/auth/refresh`,
			{},
			{
				headers: { Authorization: localStorage.getItem('refreshToken') }
			}
		)
		localStorage.setItem('accessToken', res.data.token)
		return res
	} catch (error) {
		const axiosError = error as AxiosError
		if (axiosError.response?.status === 401) {
			localStorage.removeItem('accessToken')
			localStorage.removeItem('refreshToken')
			window.location.replace('/login')
		}
		return axiosError
	}
}

/* CHECK RESET PASSWORD OTP */
export const checkResetOtp = async (body: Record<string, unknown>) => {
	try {
		const res = await axios.post<{ token: string; message: string }>(
			`${basePath}v2/auth/reset-password`,
			body
		)
		return res
	} catch (error) {
		return error as AxiosError
	}
}

/* RESET PASSWORD */
export const resetPassword = async (body: Record<string, unknown>) => {
	try {
		const res = await axios.post<{ message: string }>(
			`${basePath}v2/auth/confirm-reset-password`,
			body
		)
		return res
	} catch (error) {
		return error as AxiosError
	}
}

/* CHECK REGISTER ELIGIBILITY */
export const checkRegister = async (body: Record<string, unknown>) => {
	try {
		const res = await axios.post<{ is_eligible: boolean; message: string }>(
			`${basePath}v2/auth/check-registration-eligibility`,
			body
		)
		return res
	} catch (error) {
		return error as AxiosError
	}
}
