import { useReducer, useEffect, useState } from 'react'

import { getStrapiBrand, StrapiBrand } from '../../api/strapi'
import { rgbToHex } from '../../utils/math'
import { BEEP_THEME } from '../../utils/strapi'
import { CmsContext } from './CmsContext'
import { cmsReducer, INITIAL_CMS_STATE } from './cmsReducer'

// TODO: remove `useReducer` as it is unnecessary here.
export const CmsContextProvider = ({ children }: React.PropsWithChildren): JSX.Element | null => {
	const [state, dispatch] = useReducer(cmsReducer, INITIAL_CMS_STATE)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const fetchData = async () => {
			let strapiBrand: StrapiBrand | null = null
			try {
				strapiBrand = await getStrapiBrand()
				dispatch({
					type: 'SET_CMS_CONFIG',
					payload: strapiBrand
				})
			} catch (error) {
				console.error('Error fetching strapi config:', error)
			}
			const rootElement = document.getElementById('root')
			rootElement?.style.setProperty(
				'--main-color',
				strapiBrand?.attributes.theme?.main ?? BEEP_THEME.main
			)
			rootElement?.style.setProperty(
				'--primary-color',
				strapiBrand?.attributes.theme?.primary ?? BEEP_THEME.primary
			)
			rootElement?.style.setProperty(
				'--topbar-bg-color',
				strapiBrand?.attributes.topbar?.backgroundColor ?? BEEP_THEME.topbarBg
			)
			rootElement?.style.setProperty(
				'--topbar-icon-color',
				strapiBrand?.attributes.topbar?.itemColor ?? BEEP_THEME.topbarIcon
			)
			rootElement?.style.setProperty(
				'--slide-range-primary-color',
				rgbToHex(strapiBrand?.attributes.theme?.primary ?? BEEP_THEME.primary)
			)
			setLoading(false)
		}

		void fetchData()
	}, [])

	if (loading) {
		return null
	}
	return <CmsContext.Provider value={state}>{children}</CmsContext.Provider>
}
