import { useEffect } from 'react'
import appStoreBadge from 'src/assets/images/app_store_badge.png'
import googlePlayBadge from 'src/assets/images/google_play_badge.png'
import kinetaBadge from 'src/assets/images/kineta_badge.jpeg'
import kinetaLogoDark from 'src/assets/images/kineta_logo_dark.jpg'
import plugitLogoDark from 'src/assets/images/plugit_logo_dark.png'
import voltalityLogoDark from 'src/assets/images/voltality_logo_dark.png'

const APP_STORE_LINK = (import.meta.env.VITE_KINETA_APP_STORE_LINK as string | undefined) ?? '/'

const GOOGLE_PLAY_LINK = (import.meta.env.VITE_KINETA_GOOGLE_PLAY_LINK as string | undefined) ?? '/'

const KINETA_APP_USER_GUIDE_LINK =
	(import.meta.env.VITE_KINETA_APP_USER_GUIDE_LINK as string | undefined) ?? '/'

const SPOILER_TEXT = (import.meta.env.VITE_KINETA_SPOILER_TEXT as string | undefined) ?? ''

const KinetaTemporaryLandingScreen = (): JSX.Element | null => {
	useEffect((): void => {
		// Note: Needed so that mobile device screens can scroll to view the full height.
		document.body.style.overflow = 'scroll'
	}, [])

	return (
		<div className="flex min-h-screen flex-col items-center justify-around bg-black">
			<header className="mb-12 p-4">
				<img alt="Kineta" className="max-h-16" src={kinetaLogoDark} />
			</header>
			<main className="p-4">
				<div>
					<p className="text-center text-sm text-white">Click Below to Download App</p>
				</div>
				<div className="p-3">
					<a href={APP_STORE_LINK} target="_blank" rel="noreferrer">
						<img alt="Download on the App Store" className="h-14 w-[194px]" src={appStoreBadge} />
					</a>
				</div>
				<div className="p-3">
					<a href={GOOGLE_PLAY_LINK} target="_blank" rel="noreferrer">
						<img alt="Get it on Google Play" className="h-14 w-[194px]" src={googlePlayBadge} />
					</a>
				</div>
				<div className="p-3">
					<a href={KINETA_APP_USER_GUIDE_LINK} target="_blank" rel="noreferrer">
						<img
							alt="Kineta Charge App User Guide"
							className="h-14 w-[194px] rounded-lg border border-[#A6A6A6]"
							src={kinetaBadge}
						/>
					</a>
				</div>
			</main>
			{SPOILER_TEXT && (
				<div className="p-4">
					{SPOILER_TEXT.split('/') // Use '/' to indicate newline as Netlify Env Variables cannot handle '\n'.
						.map((textLine, index) => (
							<p className="text-center text-sm text-red-500" key={index}>
								{textLine}
							</p>
						))}
				</div>
			)}
			<footer className="p-4">
				<div className="mb-6">
					{/* Slight offset from the right to make the text look centered. */}
					<p className="mb-2 mr-2 text-center text-xs text-white">Powered By</p>
					<div className="flex items-center justify-center">
						<img className="h-8" src={plugitLogoDark} />
						<div className="mx-6 h-8 border-l-[0.5px] border-white" />
						<img className="h-8" src={voltalityLogoDark} />
					</div>
				</div>
				<div>
					<p className="text-center text-[10px] text-gray-800">
						Apple logo® and App Store® are trademarks of Apple Inc.
					</p>
					<p className="text-center text-[10px] text-gray-800">
						Google Play and the Google Play logo are trademarks of Google LLC.
					</p>
				</div>
			</footer>
		</div>
	)
}

export default KinetaTemporaryLandingScreen
