import { PropsWithChildren, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { getUserInfo } from 'src/api/user/user'

import { AuthContext } from './AuthContext'

const USER_INFO_QUERY_KEY = 'userInfo'

export const AuthContextProvider = ({ children }: PropsWithChildren) => {
	const [accessToken, setAccessToken] = useState<string | null>(localStorage.getItem('accessToken'))

	const queryClient = useQueryClient()

	const { data: userInfo = null, status: userInfoStatus } = useQuery(
		[USER_INFO_QUERY_KEY, accessToken],
		{
			enabled: !!accessToken,
			queryFn: async () => {
				if (accessToken) {
					return await getUserInfo(accessToken)
				}
				return null
			},
			select: (res) => {
				if (!!res && 'data' in res) {
					return res.data
				}
				return null
			}
		}
	)

	const setAuthTokens = (newAccessToken: string, newRefreshToken: string): void => {
		localStorage.setItem('accessToken', newAccessToken)
		localStorage.setItem('refreshToken', newRefreshToken)
		setAccessToken(newAccessToken)
		void queryClient.invalidateQueries([USER_INFO_QUERY_KEY, accessToken])
	}

	const clearAuthTokens = (): void => {
		localStorage.removeItem('accessToken')
		localStorage.removeItem('refreshToken')
		setAccessToken(null)
		void queryClient.invalidateQueries([USER_INFO_QUERY_KEY, accessToken])
	}

	if (userInfoStatus === 'loading') {
		return null
	}
	return (
		<AuthContext.Provider value={{ user: userInfo, setAuthTokens, clearAuthTokens }}>
			{children}
		</AuthContext.Provider>
	)
}
