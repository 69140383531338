export const INITIAL_USER_STATE: {
	subscribedCpoEntities: string[]
	locationPermissionOn: boolean
} = {
	subscribedCpoEntities: [],
	locationPermissionOn: false
}

export const userReducer = (
	state = INITIAL_USER_STATE,
	action: { type: string; payload?: unknown }
) => {
	switch (action.type) {
		case 'SET_SUBCRIBED_ENTITIES':
			return {
				...state,
				subscribedCpoEntities: action.payload as string[]
			}
		case 'SET_LOCATION_PERMISSION':
			return {
				...state,
				locationPermissionOn: action.payload as boolean
			}
		default:
			return state
	}
}
