import { Dispatch, createContext } from 'react'

export const UserContext = createContext<{
	locationPermissionOn: boolean
	subscribedCpoEntities: string[]
	dispatch: Dispatch<{
		type: string
		payload?: string[]
	}>
} | null>(null)
