import { extend as addDayjsPlugin } from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isToday from 'dayjs/plugin/isToday'
import { createRoot } from 'react-dom/client'

import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'

const rootElement = document.getElementById('root')

if (rootElement) {
	// Load plugins into dayjs.
	// Read More: https://day.js.org/docs/en/plugin/plugin
	addDayjsPlugin(isSameOrAfter)
	addDayjsPlugin(isSameOrBefore)
	addDayjsPlugin(isToday)

	const root = createRoot(rootElement)
	// strict mode removed for now because it causes issues with Google Maps Markers in React 18
	root.render(<App />)
	reportWebVitals()
} else {
	console.error('Root element not found!')
}
