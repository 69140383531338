import { memo } from 'react'

import LabelValueListItem from './LabelValueListItem'

interface LabelValueListProps {
	className?: string
	labelValueList: {
		label: string
		value: JSX.Element | string | null
	}[]
	title: JSX.Element | string
}

const LabelValueList = ({ className, title, labelValueList }: LabelValueListProps): JSX.Element => {
	return (
		<div className={className}>
			<div className="mb-4">
				<span className="text-sm font-bold leading-4">{title}</span>
			</div>
			{labelValueList.map(
				({ label, value }, index): JSX.Element => (
					<LabelValueListItem key={index} label={label} value={value} />
				)
			)}
		</div>
	)
}

const MemoisedLabelValueList = memo(LabelValueList)

export default MemoisedLabelValueList
