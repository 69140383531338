import { StrapiBrand } from '../../api/strapi'

export const INITIAL_CMS_STATE: {
	config: StrapiBrand | null
} = {
	config: null
}

export const cmsReducer = (
	state = INITIAL_CMS_STATE,
	action: { payload: StrapiBrand | null; type: 'SET_CMS_CONFIG' }
) => {
	switch (action.type) {
		case 'SET_CMS_CONFIG':
			return {
				config: action.payload
			}
		default:
			return state
	}
}
