import { memo } from 'react'

interface LabelValueListItemProps {
	label: string
	value: JSX.Element | string | null
}

const LabelValueListItem = ({ label, value }: LabelValueListItemProps) => {
	return (
		<div className="mb-4 flex justify-between">
			<div>
				<span className="text-sm leading-4 text-gray-500">{label}</span>
			</div>
			<div className="text-right">
				<span className="text-sm font-semibold leading-4">{value}</span>
			</div>
		</div>
	)
}

const MemoisedLabelValueListItem = memo(LabelValueListItem)

export default MemoisedLabelValueListItem
