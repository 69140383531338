import { useMemo } from 'react'
import { Routes, Route, Navigate, useLocation, Outlet } from 'react-router-dom'
import DebugLayout from 'src/components/DebugLayout'
import { useAuthContext } from 'src/hooks/useAuthContext'
import HelpScreen from 'src/screens/HelpScreen'
import KinetaTemporaryLandingScreen from 'src/screens/KinetaTemporaryLandingScreen'
import ScanScreen from 'src/screens/ScanScreen'
import SplashScreen from 'src/screens/SplashScreen'
import TermsConditionsScreen from 'src/screens/TermsConditionsScreen'
import PostOtpScreen from 'src/screens/auth/PostOtpScreen'
import { MAP_PATH } from 'src/static/enums'

import AccountScreen from '../screens/AccountScreen'
import AddPaymentScreen from '../screens/AddPaymentScreen'
import HistoryDetailScreen from '../screens/HistoryDetailScreen'
import HistoryScreen from '../screens/HistoryScreen'
import MapScreen from '../screens/MapScreen'
import PaymentMethodsScreen from '../screens/PaymentMethodsScreen'
import LoginScreen from '../screens/auth/LoginScreen'
import LogoutScreen from '../screens/auth/LogoutScreen'
import OtpScreen from '../screens/auth/OtpScreen'
import RegisterScreen from '../screens/auth/RegisterScreen'
import Main from '../screens/mains/Main'
import ResetPwMain from '../screens/mains/ResetPwMain'

const AppRoutes = () => {
	const { user } = useAuthContext()

	const isAuthenticated = !!user

	const { search } = useLocation()

	const redirectPath = useMemo((): string => {
		if (search) {
			const urlSearchParams = new URLSearchParams(search)
			const redirectParam = Array.from(urlSearchParams.entries()).find(
				([key]): boolean => key === 'redirect'
			)
			if (redirectParam) {
				const [, pathname] = redirectParam
				return pathname
			}
		}
		return MAP_PATH
	}, [search])

	// Context: Kineta uses the same QR code on their chargers that would lead users to a custom landing screen.
	// For now, this screen just shows the user that they can download the Kineta App in the Apple App Store or
	// Google Play Store. In the future, this should instead redirect the user to the VoltNow QR scanner screen,
	// and there should be a spoiler telling them to download or use the Kineta App.
	if (import.meta.env.VITE_BRAND === 'kineta') {
		return (
			<Routes>
				<Route path="/landing" element={<KinetaTemporaryLandingScreen />} />
				<Route path="*" element={<Navigate to="/landing" />} />
			</Routes>
		)
	}
	return (
		<Routes>
			<Route
				element={
					(import.meta.env.VITE_DEBUG_ROUTER as string) === 'true' ? <DebugLayout /> : <Outlet />
				}
			>
				{/* Tab routes */}
				<Route path={MAP_PATH} element={<MapScreen />} />
				<Route
					path="/history"
					element={isAuthenticated ? <HistoryScreen /> : <Navigate to="/login?redirect=/history" />}
				/>
				<Route
					path="/account"
					element={isAuthenticated ? <AccountScreen /> : <Navigate to="/login?redirect=/account" />}
				/>
				{/* Auth routes */}
				{/* <RollbarContext context="/login"> */}
				<Route
					path="/login"
					element={
						!isAuthenticated ? <LoginScreen /> : <SplashScreen redirectPath={redirectPath} />
					}
				/>
				{/* </RollbarContext> */}
				{/* <RollbarContext context="/register"> */}
				<Route
					path="/register"
					element={!isAuthenticated ? <RegisterScreen /> : <Navigate to={redirectPath} />}
				/>
				{/* </RollbarContext> */}
				<Route path="/logout" element={<LogoutScreen />} />
				{/* <RollbarContext context="/otp"> */}
				<Route path="/otp" element={!isAuthenticated ? <OtpScreen /> : <PostOtpScreen />} />
				{/* </RollbarContext> */}
				{/* <RollbarContext context="/reset-pw"> */}
				<Route path="/reset-pw" element={<ResetPwMain />} />
				{/* </RollbarContext> */}
				{/* QR Route */}
				<Route path="/qr-scanner" element={<ScanScreen />} />
				{/* <Route path="/qr-scanner" element={<NewScannerScreen />} /> */}
				{/* Other routes */}
				<Route path="/add-payment" element={<AddPaymentScreen />} />
				<Route path="/history-detail" element={<HistoryDetailScreen />} />
				<Route path="/payment-methods" element={<PaymentMethodsScreen />} />
				<Route path="/charger/:entityCode/:locationUid/:evseUid/:connectorUid" element={<Main />} />
				<Route path="/terms-conditions" element={<TermsConditionsScreen />} />
				<Route
					path="/help"
					element={isAuthenticated ? <HelpScreen /> : <Navigate to={redirectPath} />}
				/>
				<Route path="/search/:coords" element={<MapScreen />} />
				{/* Default route if no route above matches */}
				<Route path="*" element={<Navigate to={MAP_PATH} />} />
			</Route>
		</Routes>
	)
}

export default AppRoutes
