import { AxiosError } from 'axios'
import { OmniChargingSession } from 'src/schemas/chargingSession'
import { CardPaymentMethod, OmniLocation, UserInfo } from 'src/schemas/typings'

import config from '../../config'
import { axiosInstance } from '../axiosInstance'

const BASE_PATH = config.CPO_BACKEND_URL

const HEADERS = {
	headers: { Authorization: localStorage.getItem('accessToken') }
}

/* GET USER INFO */
export const getUserInfo = async (accessToken: string) => {
	try {
		const res = await axiosInstance.get<UserInfo>(`${BASE_PATH}v2/me`, {
			headers: {
				Authorization: accessToken
			}
		})
		return res
	} catch (error) {
		return error as AxiosError
	}
}

/* UPDATE USER INFO */
export const updateUserInfo = async (body: Record<string, unknown>) => {
	try {
		const res = await axiosInstance.patch(`${BASE_PATH}v2/me/update`, body, HEADERS)
		return res
	} catch (error) {
		return error as AxiosError
	}
}

/* UPDATE USER PHONE NUMBER */
export const updatePhoneNumber = async (body: Record<string, unknown>) => {
	try {
		const res = await axiosInstance.put(`${BASE_PATH}v2/me/mobile-number`, body, HEADERS)
		return res
	} catch (error) {
		return error as AxiosError
	}
}

/* GET USER'S SAVED LOCATIONS */
export const getSavedLocations = async () => {
	try {
		const res = await axiosInstance.get<OmniLocation[]>(`${BASE_PATH}v2/me/locations`, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('accessToken')
			},
			params: { requireCPODetails: true }
		})
		return res.data
	} catch (error) {
		return error as AxiosError
	}
}

/* ADD A LOCATION TO USER'S SAVED LOCATIONS */
export const saveLocation = async (locationId: string) => {
	try {
		const res = await axiosInstance.post(`${BASE_PATH}v2/me/locations/${locationId}`, {}, HEADERS)
		return res
	} catch (error) {
		return error as AxiosError
	}
}

/* DELETE A LOCATION FROM USER'S SAVED LOCATIONS */
export const deleteLocation = async (locationId: string) => {
	try {
		const res = await axiosInstance.delete(`${BASE_PATH}v2/me/locations/${locationId}`, HEADERS)
		return res
	} catch (error) {
		return error as AxiosError
	}
}

/* GET USER'S PAYMENT METHODS */
export const getPaymentMethods = async () => {
	try {
		const res = await axiosInstance.get<CardPaymentMethod[]>(
			`${BASE_PATH}v2/me/payment-method-tokens`,
			HEADERS
		)
		return res.data
	} catch (error) {
		return error as AxiosError
	}
}

/* ADD A PAYMENT METHOD */
export const addPaymentMethod = async (body: CardPaymentMethod) => {
	try {
		const res = await axiosInstance.post(`${BASE_PATH}v2/me/payment-method-tokens`, body, HEADERS)
		return res
	} catch (error) {
		return error as AxiosError
	}
}

/* DELETE USER'S PAYMENT METHOD */
export const deletePaymentMethod = async (paymentMethodTokenId: string) => {
	try {
		const res = await axiosInstance.delete(
			`${BASE_PATH}v2/me/payment-method-tokens/${paymentMethodTokenId}`,
			HEADERS
		)
		return res
	} catch (error) {
		throw error as AxiosError
	}
}

/* GET CHARGING SESSIONS */
export const getChargingSessions = async (sortBy: string, limit: number, sortDirection: string) => {
	try {
		const res = await axiosInstance.get<OmniChargingSession[]>(
			`${BASE_PATH}v2/me/sessions/?sortBy=${sortBy}&limit=${limit}&offset=0&sortDirection=${sortDirection}`,
			HEADERS
		)
		return res.data
	} catch (error) {
		return error as AxiosError
	}
}

/* GET STRIPE CLIENT SECRET */
export const getClientSecret = async () => {
	try {
		const res = await axiosInstance.get<{ clientSecret: string }>(
			`${BASE_PATH}v2/payment-method-tokens/stripe/setup-intent`,
			HEADERS
		)
		return res
	} catch (error) {
		return error as AxiosError
	}
}
