const config = {
	VOLTNET_CHARGESESSION_URL: import.meta.env.VITE_VOLTNET_CHARGESESSION_URL as string,
	VOLTNET_CHARGEPOINT_URL: import.meta.env.VITE_VOLTNET_CHARGEPOINT_URL as string,
	CPO_BACKEND_URL: import.meta.env.VITE_CPO_BACKEND_URL as string,
	FEEDBACK_LINK: import.meta.env.VITE_FEEDBACKLINK as string,
	LOCATOR_ONLY: import.meta.env.VITE_LOCATOR_ONLY as string,
	API_URL: import.meta.env.VITE_API_URL as string,
	// API_URL: process.env.NODE_ENV === "development" ? 'https://api-dev.chargegowhere.sg/v1/modules/web/' : 'https://api-dev.chargegowhere.sg/v1/modules/web/',
	LOGO: import.meta.env.VITE_LOGO_PATH as string,
	GOOGLE_CLIENT_ID: import.meta.env.VITE_GOOGLE_CLIENT_ID as string,
	GOOGLE_MAPS_API_KEY: import.meta.env.VITE_GOOGLE_MAPS_API_KEY as string,
	SCAN_URL_PREFIX:
		(import.meta.env.VITE_SCAN_URL_PREFIX as string) || 'https://chargegowhere.sg/charger',
	CLIENT_URL: import.meta.env.VITE_CLIENT_URL as string,
	ENTITY_CODE: import.meta.env.VITE_ENTITY_CODE as string,
	STRIPE_PUBLIC_KEY:
		(import.meta.env.VITE_STRIPE_PUBLIC_KEY as string) ||
		'pk_test_P2odmVRARFjM58FPAfqELVW300aSxUlj3C'
}

export default config
