import { memo } from 'react'

interface ContactCardProps {
	buttonText: string
	buttonLink: string
	buttonPrefixIcon?: JSX.Element | null
	className?: string
}

const ContactCard = ({
	buttonText,
	buttonLink,
	buttonPrefixIcon,
	className = ''
}: ContactCardProps): JSX.Element | null => {
	return (
		<div className={['flex flex-col rounded-lg p-5', className].join(' ').trim()}>
			<div className="mb-3">
				<p className="mb-2 text-center text-base font-semibold">Unable to find an answer?</p>
				<p className="text-center text-sm text-zinc-600">Contact us here:</p>
			</div>
			<div>
				<a
					className="flex justify-center rounded-md bg-primary px-4 py-3"
					href={buttonLink}
					target="_blank" rel="noreferrer"
				>
					{buttonPrefixIcon}
					<span className="text-base font-medium text-white">{buttonText}</span>
				</a>
			</div>
		</div>
	)
}

const MemoisedContactCard = memo(ContactCard)

export default MemoisedContactCard
