import axios from 'axios'
import { CSSProperties } from 'react'
import { STRAPI_URL } from 'src/static/enums'

interface StrapiImage {
	id: number
	// The `attributes` here shouldn't be `undefined`, but they could be `null`.
	attributes: {
		name: string | null
		alternativeText: string | null
		caption: string | null
		width: number | null
		height: number | null
		formats: {
			thumbnail: {
				name: string | null
				hash: string | null
				ext: string | null
				mime: string | null
				path: string | null
				width: number | null
				height: number | null
				size: number | null
				url: string | null
			}
			small: {
				name: string | null
				hash: string | null
				ext: string | null
				mime: string | null
				path: string | null
				width: number | null
				height: number | null
				size: number | null
				url: string | null
			}
		}
		hash: string | null
		ext: string | null
		mime: string | null
		size: number | null
		url: string | null
		previewUrl: null
		provider: string | null
		provider_metadata: string | null
		createdAt: string | null
		updatedAt: string | null
	}
}

export interface StrapiBrand {
	id: number
	attributes: {
		// Base Key-Values
		name: string | null
		createdAt: string | null // `created/updated/publishedAt` Format: YYYY-MM-DDTHH:mm:ss.SSSZ
		publishedAt: string | null
		updatedAt: string | null
		// Custom Key-Values
		configuration: Partial<{
			cpoSortOrder: string[]
		}> | null
		logoDark: {
			data: StrapiImage | null
		}
		logoLight: {
			data: StrapiImage | null
		}
		theme: Partial<{
			// FUTURE TODO: Consistent structure for theming of components.
			main: string
			primary: string
			components: Partial<Record<string, CSSProperties>> | null
		}> | null
		topbar: Partial<{
			backgroundColor: string
			itemColor: string
			logoHeight: string
			logoTheme: 'dark' | 'light'
		}> | null
	}
}

interface StrapiResponse {
	data: StrapiBrand[]
	// Note: Should not need to be concerned about pagination meta as long as the brand name is unique.
	meta: {
		pagination: {
			page: number
			pageCount: number
			pageSize: number
			total: number
		}
	}
}

// FUTURE TODO: `useQuery` should be done here; all other APIs and typings should be cleaned up too.
export const getStrapiBrand = async (): Promise<StrapiBrand> => {
	try {
		const axiosResponse = await axios.get<StrapiResponse>(
			`${STRAPI_URL}/api/brands?filters[name][$eq]=${import.meta.env.VITE_BRAND}&populate=*`
		)
		const strapiResponse = axiosResponse.data
		// Assumes that there are no duplicate `Brand` collection types.
		if (strapiResponse.data[0]) {
			return strapiResponse.data[0]
		}
	} catch (error) {
		console.error(error)
	}
	return {
		id: -1,
		attributes: {
			name: null,
			createdAt: null,
			publishedAt: null,
			updatedAt: null,
			configuration: null,
			logoDark: {
				data: null
			},
			logoLight: {
				data: null
			},
			theme: null,
			topbar: null
		}
	}
}
