import { memo, useState } from 'react'
import MinusIcon from 'src/assets/svgs/MinusIcon'
import PlusIcon from 'src/assets/svgs/PlusIcon'

interface CollapseProps {
	title: JSX.Element | string
	description: JSX.Element | string
}

const Collapse = ({ title, description }: CollapseProps): JSX.Element => {
	const [isCollapsed, setIsCollapsed] = useState<boolean>(true)

	const handleToggleIsCollapsedClick = (): void => {
		setIsCollapsed(!isCollapsed)
	}

	return (
		<div className="mb-4 border-gray-200 [&:last-child]:mb-6 [&:not(:last-child)]:border-b-[1px]">
			<div className="mb-3 flex justify-between">
				<div className="mr-3">
					<h3 className="hyphens-auto text-base font-medium">{title}</h3>
				</div>
				<div>
					<button name={isCollapsed ? 'expand' : 'collapse'} onClick={handleToggleIsCollapsedClick}>
						{isCollapsed ? <PlusIcon className="h-6 w-6" /> : <MinusIcon className="h-6 w-6" />}
					</button>
				</div>
			</div>
			{!isCollapsed && <div className="mb-3 text-sm text-zinc-600">{description}</div>}
		</div>
	)
}

const MemoisedCollapse = memo(Collapse)

export default MemoisedCollapse
