import { useReducer, useEffect, PropsWithChildren } from 'react'

import { ModeContext } from './ModeContext'

const modeReducer = (state: { darkMode: boolean }, action: { type: string; payload: boolean }) => {
	switch (action.type) {
		case 'DARKMODE':
			return { ...state, darkMode: action.payload }
		default:
			return state
	}
}

export const ModeContextProvider = ({ children }: PropsWithChildren) => {
	const [state, dispatch] = useReducer(modeReducer, {
		darkMode: false
	})
	useEffect(() => {
		const darkMode = localStorage.getItem('darkMode')
		if (darkMode === 'true') {
			dispatch({ type: 'DARKMODE', payload: true })
		} else {
			dispatch({ type: 'DARKMODE', payload: false })
		}
	}, [])
	return <ModeContext.Provider value={{ ...state, dispatch }}>{children}</ModeContext.Provider>
}
