import { CSSProperties, memo } from 'react'

interface TagProps {
	value: string | number
	/**
	 * Additional classes to be added to `Tag`.
	 */
	className?: string
	/**
	 * Default styling for `Tag` can be overriden with the `style` prop.
	 */
	style?: CSSProperties
}

const Tag = ({ value, className = '', style }: TagProps) => {
	return (
		<div
			className={['rounded-xl bg-black px-1.5 py-0.5 text-xs font-medium text-white', className]
				.join(' ')
				.trim()}
			style={style}
		>
			{value}
		</div>
	)
}

const MemoisedTag = memo(Tag)

export default MemoisedTag
