import { AxiosResponse } from 'axios'
import {
	OmniChargingSession,
	OmniChargingSessionResponse,
	OmniTariff
} from 'src/schemas/chargingSession'
import { OmniConnector, OmniEVSE } from 'src/schemas/evse'
import { OmniLocation } from 'src/schemas/typings'

import config from '../../config'
import { StartChargeReq, StartChargeResp } from '../../schemas/api'
import { axiosInstance, parseAxiosResponseError } from '../axiosInstance'

const BASE_PATH = config.CPO_BACKEND_URL

const HEADERS = { Authorization: localStorage.getItem('accessToken') }

/* GET LATEST SESSION FROM PARTICULAR EVSE'S CONNECTOR */
export const getLatestEvseConnectorSession = async (
	locationId: string,
	evseId: string,
	connectorId: string
) => {
	try {
		const res = await axiosInstance.get<OmniChargingSession>(
			`${BASE_PATH}v3/locations/${locationId}/${evseId}/${connectorId}/current-session`
		)
		return res
	} catch (error) {
		const axiosError = parseAxiosResponseError(error as Error)
		if (typeof axiosError === 'object') {
			// Handle for non-existent current session.
			if (axiosError.status === 404 && axiosError.data === null) {
				return axiosError as AxiosResponse<null>
			}
		}
		throw error as Error
	}
}

/* GET LATEST USER SESSION */
export const getLatestUserSession = async (isRealTime: boolean) => {
	try {
		const res = await axiosInstance.get<{
			data: {
				session: OmniChargingSession
			}
			message: string
			status: number
		}>(`${BASE_PATH}v2/me/sessions/latest?is_real_time=${isRealTime}`, {
			headers: HEADERS
		})
		return res
	} catch (error) {
		const axiosError = parseAxiosResponseError<{
			data: {
				session: OmniChargingSession
			} | null
			message: string
			status: number
		}>(error as Error)
		if (typeof axiosError === 'object') {
			// Handle for non-existent current session.
			if (axiosError.status === 404 && axiosError.data.data === null) {
				return axiosError as AxiosResponse<{
					data: null
					message: string
					status: number
				}>
			}
		}
		throw error as Error
	}
}

/* GET ALL SESSIONS FROM USER */
export const getAllUserSessions = async (limit?: number) => {
	try {
		const params = {
			limit
		}
		const res = await axiosInstance.get<OmniChargingSession[]>(`${BASE_PATH}v2/me/sessions`, {
			params,
			headers: HEADERS
		})
		return res
	} catch (error) {
		return error as Error
	}
}
/* GET EVSE DETAILS */
export const getEvse = async (locationId: string, evseId: string) => {
	try {
		const res = await axiosInstance.get<OmniEVSE>(
			`${BASE_PATH}v3/locations/${locationId}/${evseId}`
		)
		return res
	} catch (error) {
		return error as Error
	}
}

/* GET CONNECTOR DETAILS */
export const getConnector = async (locationId: string, evseId: string, connectorId: string) => {
	try {
		const res = await axiosInstance.get<OmniConnector>(
			`${BASE_PATH}v3/locations/${locationId}/${evseId}/${connectorId}`
		)
		return res
	} catch (error) {
		return error as Error
	}
}

/* GET CONNECTOR TARIFF */
export const getTariff = async (tariff_uid: string) => {
	try {
		const res = await axiosInstance.get<OmniTariff>(`${BASE_PATH}v3/tariffs/${tariff_uid}`)
		return res
	} catch (error) {
		return error as Error
	}
}

/* GET CURRENT CHARGING SESSION */
export const getCurrentChargingSession = async (sessionId: string) => {
	try {
		const res = await axiosInstance.get<OmniChargingSessionResponse>(
			`${BASE_PATH}v3/sessions/${sessionId}`,
			{
				headers: HEADERS
			}
		)
		return res
	} catch (error) {
		return error as Error
	}
}

/* GET LIST OF LOCATIONS WITH PAGINATION */
export const getAllLocations = async () => {
	try {
		let counter = 0
		let finishedPagination = false
		let totalLocations: OmniLocation[] = []
		while (!finishedPagination) {
			const res = await axiosInstance.get<{ locations: OmniLocation[]; count: number }>(
				`${BASE_PATH}v3/locations?offset=${counter}&limit=500`,
				{
					headers: HEADERS
				}
			)
			const { locations, count } = res.data
			if (count < 500) {
				finishedPagination = true
			}
			totalLocations = [...totalLocations, ...locations]
			counter += count
		}
		return totalLocations
	} catch (error) {
		return error as Error
	}
}

/* GET A LOCATION' */
export const getLocation = async (locationId: string) => {
	try {
		const res = await axiosInstance.get<OmniLocation>(`${BASE_PATH}v3/locations/${locationId}`, {})
		return res
	} catch (error) {
		return error as Error
	}
}

/* POST START CHARGE */
export const startCharge = async (
	locationId: string,
	evseId: string,
	paymentTokenId: string,
	connectorId: string,
	license_plate: string
) => {
	try {
		const requestBody: StartChargeReq = {
			location_uid: locationId,
			evse_uid: evseId,
			connector_uid: connectorId,
			payment_token_uid: paymentTokenId,
			license_plate: license_plate
		}
		const res: StartChargeResp = await axiosInstance.post(
			`${BASE_PATH}v3/sessions/start`,
			requestBody,
			{ headers: HEADERS }
		)
		return res
	} catch (error) {
		throw error as Error
	}
}

/* POST STOP CHARGE */
export const stopCharge = async (sessionID: string) => {
	try {
		const res = await axiosInstance.post(
			`${BASE_PATH}v3/sessions/${sessionID}/stop`,
			{},
			{ headers: HEADERS }
		)
		return res
	} catch (error) {
		throw error as Error
	}
}
