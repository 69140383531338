import { useReducer, useEffect, PropsWithChildren } from 'react'

import { useAuthContext } from '../../hooks/useAuthContext'
import { UserContext } from './UserContext'
import { userReducer, INITIAL_USER_STATE } from './userReducer'

export const UserContextProvider = ({ children }: PropsWithChildren) => {
	const [state, dispatch] = useReducer(userReducer, INITIAL_USER_STATE)

	const { user } = useAuthContext()

	useEffect(() => {
		// TODO: Remove this once Kineta VoltNow is to be done.
		if (import.meta.env.VITE_BRAND === 'kineta') {
			return
		}
		navigator.geolocation.getCurrentPosition(function () {
			dispatch({
				type: 'SET_LOCATION_PERMISSION',
				payload: true
			})
		})
	}, [user])

	useEffect(() => {
		const handlePermissionChange = (event: Event) => {
			const target = event.target as (EventTarget & { state: string }) | null
			if (target?.state === 'granted') {
				dispatch({
					type: 'SET_LOCATION_PERMISSION',
					payload: true
				})
			} else {
				dispatch({
					type: 'SET_LOCATION_PERMISSION',
					payload: false
				})
			}
		}

		const locationPermissionStatus = navigator.permissions.query({
			name: 'geolocation'
		})

		void locationPermissionStatus.then((status) => {
			status.addEventListener('change', handlePermissionChange)
		})

		return () => {
			void locationPermissionStatus.then((status) => {
				status.removeEventListener('change', handlePermissionChange)
			})
		}
	}, [])

	return <UserContext.Provider value={{ ...state, dispatch }}>{children}</UserContext.Provider>
}
