import { useContext } from 'react'
import { AuthContext } from 'src/context/AuthContext/AuthContext'

export const useAuthContext = () => {
	const context = useContext(AuthContext)

	if (!context) {
		throw Error('AuthContext: useAuthContext must be used inside an AuthContextProvider!')
	}

	return context
}
