import { SVGProps, memo } from 'react'

const WhatsAppIcon = ({
	viewBox = '0 0 22 22',
	fill = 'none',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11 21.3125C16.3157 21.3125 20.625 17.0032 20.625 11.6875C20.625 6.37176 16.3157 2.0625 11 2.0625C5.68426 2.0625 1.375 6.37176 1.375 11.6875C1.375 13.4137 1.82944 15.0338 2.6252 16.4347L1.375 21.3125L6.40397 20.1464C7.7697 20.89 9.33551 21.3125 11 21.3125ZM11 19.8317C15.4979 19.8317 19.1442 16.1854 19.1442 11.6875C19.1442 7.18957 15.4979 3.54327 11 3.54327C6.50207 3.54327 2.85577 7.18957 2.85577 11.6875C2.85577 13.4242 3.39935 15.0339 4.32564 16.3558L3.59615 19.0913L6.37995 18.3954C7.69269 19.3013 9.28439 19.8317 11 19.8317Z"
				fill="#BFC8D0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11 20.625C16.3157 20.625 20.625 16.3157 20.625 11C20.625 5.68426 16.3157 1.375 11 1.375C5.68426 1.375 1.375 5.68426 1.375 11C1.375 12.7262 1.82944 14.3463 2.6252 15.7472L1.375 20.625L6.40397 19.4589C7.7697 20.2025 9.33551 20.625 11 20.625ZM11 19.1442C15.4979 19.1442 19.1442 15.4979 19.1442 11C19.1442 6.50207 15.4979 2.85577 11 2.85577C6.50207 2.85577 2.85577 6.50207 2.85577 11C2.85577 12.7367 3.39935 14.3464 4.32564 15.6683L3.59615 18.4038L6.37995 17.7079C7.69269 18.6138 9.28439 19.1442 11 19.1442Z"
				fill="white"
			/>
			<path
				d="M8.59377 6.5312C8.36492 6.07156 8.01386 6.11225 7.65921 6.11225C7.0254 6.11225 6.03711 6.87144 6.03711 8.28437C6.03711 9.44235 6.54737 10.7099 8.26679 12.6061C9.92615 14.4361 12.1065 15.3827 13.9165 15.3505C15.7266 15.3183 16.099 13.7606 16.099 13.2346C16.099 13.0015 15.9543 12.8851 15.8546 12.8535C15.2378 12.5575 14.1002 12.0059 13.8413 11.9023C13.5825 11.7987 13.4473 11.9388 13.3633 12.0151C13.1286 12.2388 12.6632 12.8981 12.5039 13.0464C12.3446 13.1946 12.1071 13.1196 12.0082 13.0635C11.6445 12.9176 10.6583 12.4789 9.87213 11.7168C8.89991 10.7744 8.84285 10.4501 8.65968 10.1615C8.51315 9.93059 8.62068 9.78892 8.67433 9.727C8.8838 9.48531 9.17304 9.11215 9.30275 8.92671C9.43246 8.74127 9.32949 8.45973 9.2677 8.28438C9.00197 7.53023 8.77684 6.89892 8.59377 6.5312Z"
				fill="white"
			/>
		</svg>
	)
}

const MemoisedWhatsAppIcon = memo(WhatsAppIcon)

export default MemoisedWhatsAppIcon
