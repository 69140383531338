import { BEEP_THEME_COLORS, BEEP_TOPBAR } from '../static/enums'

interface Theme {
	main: string
	primary: string
	topbarBg: string
	topbarIcon: string
}

export const BEEP_THEME: Theme = {
	main: BEEP_THEME_COLORS.main,
	primary: BEEP_THEME_COLORS.primary,
	topbarBg: BEEP_TOPBAR.backgroundColor,
	topbarIcon: BEEP_TOPBAR.itemColor
}
