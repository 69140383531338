import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import { refresh } from './auth/auth'

export const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(
	(config) => {
		config.headers.Authorization = localStorage.getItem('accessToken')
		return config
	},
	(error) => {
		return Promise.reject(new Error(String(error)))
	}
)

axiosInstance.interceptors.response.use(
	(response) => {
		return response
	},
	async function (error: {
		config: AxiosRequestConfig & { _retry: boolean }
		response: { status: number }
	}) {
		const originalRequest = error.config
		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true
			const response = await refresh()
			if ('data' in response) {
				axios.defaults.headers.common.Authorization = response.data.token
			}
			return await axiosInstance(originalRequest)
		}
		return Promise.reject(new Error(JSON.stringify(error.response)))
	}
)

export const parseAxiosResponseError = <T = unknown,>(error: Error): AxiosResponse<T> | string => {
	try {
		const errorResponse = JSON.parse(error.message) as AxiosResponse<T>
		console.error(errorResponse)
		return errorResponse
	} catch {
		console.error(error.message)
	}
	return error.message
}
